import { Button, Container, Input } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Headline, List } from './Clients.Styled'
import { Operations, crudInterface, useCrudReadOperation } from '@think-internet/zeus-frontend-package'
import Item from './Item/Item'
import { useState } from 'react'

const Clients = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const clients = useCrudReadOperation(props.CLIENT, Operations.LIST, {}, undefined, undefined, [])
  const [name, setName] = useState('')

  const create = async () => {
    await crudInterface.create(token, props.CLIENT, { name })
    setName('')
  }

  return (
    <Layout>
      <Container>
        <Headline>{translation.dashboard.clients.headline}</Headline>
        <Input value={name} onChange={setName} placeholder="Name" />
        <Button onClick={create} text="Erstellen" />
        <List>
          {clients?.map((client) => (
            <Item client={client} />
          ))}
        </List>
      </Container>
    </Layout>
  )
}

export default Clients
