import PageLayout from '../PageLayout/PageLayout'
import Textarea from '../Textarea/Textarea'
import { Headline, InputWrapper, Seperator } from '../Wishes/Wishes.Styled'

import _ from 'lodash'
import ColumnsLayout from '../ColumnsLayout/ColumnsLayout'

const Potentials = ({ workshop, setWorkshop }) => {
  const update = (i, key) => (e) => {
    const values = [...workshop[key]]
    values[i] = e.target.value
    setWorkshop({ ...workshop, [key]: values })
  }

  const columns = [
    <>
      <Headline>Hürden &</Headline>
      <Headline>Aktuelle Herausforderungen</Headline>
      <Seperator />
      <InputWrapper>
        {_.times(7).map((i) => (
          <Textarea key={i} value={workshop.challenges[i] || ''} onChange={update(i, 'challenges')} />
        ))}
      </InputWrapper>
    </>,
    <>
      <Headline>Optimierungen</Headline>
      <Seperator />
      <InputWrapper>
        {_.times(7).map((i) => (
          <Textarea key={i} value={workshop.optimizes[i] || ''} onChange={update(i, 'optimizes')} />
        ))}
      </InputWrapper>
    </>,
    <>
      <Headline>Marktchancen &</Headline>
      <Headline>Unternehmenspotentiale</Headline>
      <Seperator />
      <InputWrapper>
        {_.times(7).map((i) => (
          <Textarea key={i} value={workshop.potentials[i] || ''} onChange={update(i, 'potentials')} />
        ))}
      </InputWrapper>
    </>,
  ]

  return (
    <PageLayout>
      <ColumnsLayout columns={columns} />
    </PageLayout>
  )
}

export default Potentials
