import { IBaseTheme, IComponentPackageStyling } from './theme.d'

const black = 'rgb(51,51,51)'
const white = '#fafbfd'
const lightgray = 'rgb(245, 245, 245)'
const gray = '#838282'
const red = '#E74C3C'
const green = '#1ABC9C'

const theme: IBaseTheme = {
  font: {
    size: {
      sm: '16px',
      md: '18px',
      lg: '22px',
      xlg: '46px',
    },
  },
  color: {
    font: {
      primary: black,
      secondary: white,
      thirdary: '#cad1ff',
      hint: gray,
      danger: red,
      lightgray: lightgray,
      success: green,
    },
    background: {
      primary: lightgray,
      secondary: '#c9d1ff',
      thirdary: white,
      fourth: '#21253d',
    },
    border: {
      primary: black,
      secondary: white,
      thirdary: lightgray,
    },
    button: {
      primary: white,
      secondary: black,
      borderPrimary: lightgray,
      borderSecondary: white,
      danger: red,
    },
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  border: {
    radius: '5px',
  },
  tagColors: {
    RED: '#E74C3C',
    LIGHTRED: '#EC7063',
    LILA: '#8E44AD',
    BLUE: '#3498DB',
    GREEN: '#1ABC9C',
    YELLOW: '#F1C40F',
    ORANGE: '#E67E22',
    DARKBLUE: '#34495E',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
  headerHeight: '50px',
  projectControlsHeight: '60px',
}

const adjustedComponentStyling: IComponentPackageStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: ${theme.color.background.thirdary};
    border-radius: 0px;
    border: none;
    transition: all 0.25s ease;
    :hover {
      background-color: ${theme.color.button.secondary};
      border-color: transparent;
      color: ${theme.color.font.secondary};
    }
    `,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `border: none; background-color: ${theme.color.background.fourth}`,
    title: ``,
    hint: `color: ${theme.color.font.hint}; font-size: ${theme.font.size.sm}; font-family: 'thin'`,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `width: 100%;
    `,
    select: `
    border: none;
    border-radius: 0px;
    border-bottom: thin solid ${theme.color.border.thirdary};
    padding-left: 0px;
    `,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
      border: none;
      border-radius: 0px;
      border-bottom: thin solid ${theme.color.border.thirdary};
      background-color: ${theme.color.background.thirdary};
      transition: border-bottom 0.25s ease-in-out;
      &:active, &:focus {
        border-bottom: thin solid ${theme.color.border.primary};
      }
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: ``,
    background: ``,
    core: ``,
    header: ``,
    title: `
    font-family: bold;
    `,
    close: ``,
    content: `
    `,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `
    border: none;
    border-radius: 0px;
    border-bottom: thin solid ${theme.color.border.thirdary};
    padding-left: 0px;
    transition: border-bottom 0.25s ease-in-out;
    &:active, &:focus {
      border-bottom: thin solid ${theme.color.border.primary};
    }
    `,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme
