import { Operations } from '@think-internet/zeus-frontend-package'
import defaultState from './defaultState'
import { State, Action, ReplacePayload } from './types'

const getPayload = (state: State, action: Action, replacePayload: ReplacePayload, reverseOrder = false) => {
  if (replacePayload) {
    return action.payload
  } else if (Array.isArray(state[action.type])) {
    if (Array.isArray(action.payload)) {
      return reverseOrder ? [...state[action.type], ...action.payload] : [...action.payload, ...state[action.type]]
    } else {
      return reverseOrder ? [...state[action.type], action.payload] : [action.payload, ...state[action.type]]
    }
  } else if (Array.isArray(action.payload)) {
    return action.payload
  }
  return [action.payload]
}

const reducer = (state: State = defaultState, action: Action) => {
  if (!action.operation) return state
  if (action.operation === Operations.DELETE) {
    const newState = { ...state }
    delete newState[action.type]
    return newState
  }
  const replacePayload: ReplacePayload = action.replace !== undefined ? action.replace : true

  return {
    ...state,
    [action.type]: getPayload(state, action, replacePayload, action.reverseOrder),
  }
}

export default reducer
