import styled from 'styled-components'

const StyledTextarea = styled.textarea`
  padding: 5px;
  &:focus {
    outline: none;
  }
  width: 100%;

  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
  font-size: 1.5vh;
`

const Textarea = ({ value, onChange }) => {
  return <StyledTextarea value={value} onChange={onChange} rows={3} />
}

export default Textarea
