import styled from 'styled-components'

export const StyledImageLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex-direction: column;
  }
`
export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 50%;
  height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
    height: 50vh;
  }
`
export const Column = styled.div`
  width: 50%;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 5vh;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
  }
`
