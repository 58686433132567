import styled from 'styled-components'
import PageLayout from '../PageLayout/PageLayout'
import startImg from '../../../../assets/image/workshop/start.png'
import logoBig from '../../../../assets/image/workshop/logoBig.png'

const Image = styled.div`
  background-image: url(${startImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  width: 100%;
  padding: 0 2.5vw;
`

const Session = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Headline = styled.div`
  font-size: 6.5rem;
  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;
`
const SubHeadline = styled(Headline)`
  color: white;
  text-align: center;
  font-weight: normal;
`

const Seperator = styled.div`
  width: 100%;
  width: 2px;
  background-color: white;
  height: 25vh;
`

const Logo = styled.img`
  width: 35vw;
  margin: 0 auto;
`

const Text = styled.div`
  font-size: 1.5rem;
  color: white;
`

const Start = () => {
  return (
    <PageLayout hideLogo>
      <Image>
        <Flex>
          <Session>
            <Flex>
              <Headline>Digital</Headline>
              <SubHeadline>Session</SubHeadline>
            </Flex>
            <Text>60-Minute-quick-strategy // 2023</Text>
          </Session>
          <Seperator />
          <Logo src={logoBig} />
        </Flex>
      </Image>
    </PageLayout>
  )
}

export default Start
