import { StyledPageLayout, Logo } from './PageLayout.Styled'
import logo from '../../../../assets/image/logo.png'

const PageLayout = ({ children, hideLogo = false }) => {
  return (
    <StyledPageLayout>
      {!hideLogo && <Logo src={logo} />}
      {children}
    </StyledPageLayout>
  )
}

export default PageLayout
