import styled from 'styled-components'

export const Columns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex-direction: column;
  }
`

export const Column = styled.div<{ even?: boolean; first?: boolean; last?: boolean; width: string }>`
  width: ${({ width }) => width};

  padding-bottom: 50px;
  padding-top: ${({ even }) => (even ? '150px' : '50px')};
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
  }
`

export const Action = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-size: 100% auto; */
  width: 100%;
  height: 45vh;
  position: relative;
  margin-top: 100px;
`

export const Arrow = styled.img<{ direction: string }>`
  width: 15vw;
  position: absolute;

  ${({ direction, theme }) => (direction === 'left' ? `top: 0; right: 0;` : `bottom: 0; left: 0; transform: translateZ(0) rotate(180deg);`)}
`

export const Wrapper = styled.div<{ direction?: string }>`
  display: flex;
  ${({ direction, theme }) =>
    direction === 'top'
      ? `
  border-top: 1px solid ${theme.color.background.secondary};
  // border-right: 1px solid ${theme.color.background.secondary};
  padding-right: 50px;
  padding-bottom: 20px;
  `
      : `
      border-bottom: 1px solid ${theme.color.background.secondary};
  // border-left: 1px solid ${theme.color.background.secondary};
  padding-left: 50px;
  padding-top: 20px;`}

  :last-child {
    padding-bottom: 0px;
  }

  :first-child {
    padding-top: 0px;
  }
`

export const List = styled.div<{ direction: string }>`
  ${({ direction, theme }) =>
    direction === 'top'
      ? `

  border-right: 1px solid ${theme.color.background.secondary};

  `
      : `
  border-left: 1px solid ${theme.color.background.secondary};`}
`
