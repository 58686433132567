import { StyledImageLayout, Column } from './ColumnsLayout.styles'

const ColumnsLayout = ({ columns }) => {
  return (
    <StyledImageLayout>
      {columns.map((column, index) => (
        <Column first={index === 0} last={columns.length === index + 1} even={index % 2 === 0} key={index}>
          {column}
        </Column>
      ))}
    </StyledImageLayout>
  )
}

export default ColumnsLayout
