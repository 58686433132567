import { StyledLogin, Flex, FormWrapper, Form, LogoText } from './Login.Styled'
import { Button, Input } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'

const Login = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()

  useEffect(() => {
    const checkToken = async () => {
      const account = await auth.resolveToken(token)
      if (account && account.level) {
        navigate(`/dashboard`)
      }
    }
    if (token) {
      checkToken()
    }
  }, [token, navigate])

  const submit = async () => {
    const token = await auth.login(identifier, password)
    if (token) {
      cookies.set('token', token, { path: '/' })
      const account = await auth.resolveToken(token)
      if (account && account.level) {
        navigate(`/dashboard`)
      } else {
        toast(TYPE.ERROR, translation.login.error)
      }
    } else {
      toast(TYPE.ERROR, translation.login.error)
    }
  }

  return (
    <Layout authRequired={false}>
      <StyledLogin>
        <FormWrapper>
          <Form onSubmit={submit}>
            <Flex>
              <LogoText>{translation.logoText}</LogoText>
              <Input placeholder={translation.generic.mail} onChange={setIdentifier} value={identifier} required={true} type="email" />
              <Input placeholder={translation.generic.password} onChange={setPassword} value={password} required={true} type="password" />
              <Button type="submit" text={translation.login.cta} />
            </Flex>
          </Form>
        </FormWrapper>
      </StyledLogin>
    </Layout>
  )
}

export default Login
