import styled from 'styled-components'

export const StyledPageLayout = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.background.fourth};
  position: relative;
  overflow: hidden;
`

export const Logo = styled.img`
  width: 2vw;
  position: absolute;
  top: 50px;
  right: 50px;
`
