import { Operations, crudInterface, useCrudReadOperation } from '@think-internet/zeus-frontend-package'
import { useSearchParams } from 'react-router-dom'
import props from '../../../redux/props'
import Layout from '../../Layout/Layout'
import { useEffect, useState } from 'react'
import Wishes from './Wishes/Wishes'
import Challanges from './Challanges/Challanges'
import Potentials from './Potentials/Potentials'
import Optimizes from './Optimizes/Optimizes'
import Doings from './Doings/Doings'
import RoadMap from './RoadMap/RoadMap'
import { useSelector } from 'react-redux'
import Start from './Start/Start'
import End from './End/End'

const Workshop = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('uuid')
  const initialWorkshop = useCrudReadOperation(props.WORKSHOP, Operations.GET, { uuid }, undefined, undefined, [uuid])
  const [workshop, setWorkshop] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const token = useSelector((s) => s[props.TOKEN])

  const pages = [Start, Wishes, Challanges, Potentials, Optimizes, Doings, RoadMap, End]
  const pagesCount = pages.length

  const CurrentPage = pages[currentIndex]

  useEffect(() => {
    if (initialWorkshop && !workshop) setWorkshop(initialWorkshop)

    const changeIndex = async (i) => {
      if (i < 0) {
        return
      } else if (i > pagesCount - 1) {
        return
      } else {
        if (workshop) {
          await crudInterface.update(token, props.WORKSHOP, workshop) // { ...newWorkshop, doings: newWorkshop.doings.map((item) => item.value) }
          setCurrentIndex(i)
        }
      }
    }

    const event = (e) => {
      if (e.keyCode === 38) {
        changeIndex(currentIndex - 1)
      }
      if (e.keyCode === 40) {
        changeIndex(currentIndex + 1)
      }
    }

    document.addEventListener('keydown', event)

    return () => {
      document.removeEventListener('keydown', event)
    }
  }, [initialWorkshop, workshop, currentIndex, token, pagesCount])

  const getPage = () => {
    if (workshop) return <CurrentPage workshop={workshop} setWorkshop={setWorkshop} />
    else <></>
  }

  return <Layout hideHeader={true}>{getPage()}</Layout>
}

export default Workshop
