import PageLayout from '../PageLayout/PageLayout'
import ImageLayout from '../ImageLayout/ImageLayout'
import wishesImage from '../../../../assets/image/workshop/wishes.png'
import _ from 'lodash'
import { Headline, Seperator, Image, InputWrapper, ImageWrapper } from './Wishes.Styled'
import Textarea from '../Textarea/Textarea'
import wishes1 from '../../../../assets/image/workshop/wishes1.png'

const Wishes = ({ workshop, setWorkshop }) => {
  const update = (i) => (e) => {
    const wishes = [...workshop.wishes]
    wishes[i] = e.target.value
    setWorkshop({ ...workshop, wishes })
  }

  const getYear = () => {
    const date = new Date(workshop?.createdOn || Date.now())
    return date.getFullYear() + 3
  }

  //REVIEW translations

  return (
    <PageLayout>
      <ImageLayout image={wishesImage}>
        <Headline>Im Jahr {getYear()}</Headline>
        <Seperator />
        <ImageWrapper>
          <Image src={wishes1} />
        </ImageWrapper>
        <InputWrapper>
          {_.times(4).map((i) => (
            <Textarea key={i} value={workshop.wishes[i] || ''} onChange={update(i)} />
          ))}
        </InputWrapper>
      </ImageLayout>
    </PageLayout>
  )
}

export default Wishes
