import PageLayout from '../PageLayout/PageLayout'
import actionImg from '../../../../assets/image/workshop/action.png'
import arrowImg from '../../../../assets/image/workshop/arrow.png'
import { Headline, Seperator } from '../Wishes/Wishes.Styled'
import { Columns, Column, Action, Wrapper, Arrow, List } from './Doings.Styled'
import Textarea from '../Textarea/Textarea'
import _ from 'lodash'

const CustomTextarea = ({ value, onChange, direction }) => {
  return (
    <Wrapper direction={direction}>
      <Textarea value={value} onChange={onChange} />
    </Wrapper>
  )
}

const Doings = ({ workshop, setWorkshop }) => {
  const update = (i, key) => (e) => {
    const values = [...workshop[key]]
    values[i] = e.target.value
    setWorkshop({ ...workshop, [key]: values })
  }
  return (
    <PageLayout>
      <Columns>
        <Column even width="30%">
          <Headline align="left" color="white">
            Optimierungen
          </Headline>
          <Seperator align="left" color="white" />
          <List direction={'top'}>
            {_.times(7).map((i) => (
              <CustomTextarea key={i} value={workshop.optimizes[i] || ''} onChange={update(i, 'optimizes')} direction={'top'} />
            ))}
          </List>
        </Column>
        <Column width="40%">
          <Headline>Action journey</Headline>
          <Seperator />
          <Action src={actionImg}>
            <Arrow direction="left" src={arrowImg} />
            <Arrow direction="right" src={arrowImg} />
          </Action>
        </Column>
        <Column even width="30%">
          <Headline align="right" color="white">
            Umsetzung
          </Headline>
          <Seperator align="right" color="white" />
          <List direction={'bottom'}>
            {_.times(7).map((i) => (
              <CustomTextarea key={i} value={workshop.doings[i] || ''} onChange={update(i, 'doings')} direction={'bottom'} />
            ))}
          </List>
        </Column>
      </Columns>
    </PageLayout>
  )
}

export default Doings
