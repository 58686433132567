import styled from 'styled-components'

interface CoreContentProps {
  hideHeader?: boolean
  sideMenuItems?: boolean
}

export const StyledLayout = styled.div``

export const CoreContent = styled.div<CoreContentProps>`
  background-color: ${({ theme }) => theme.color.background.primary};
  min-height: calc(100vh - ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeight)});
  margin: ${({ sideMenuItems }) => (sideMenuItems ? '0px 0px 50px 200px' : '0px')};
  margin-top: ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeight)};
  width: 100vw;
`
