import styled from 'styled-components'
import { Form as BaseForm } from '@think-internet/ui-components'

export const StyledLogin = styled.div``

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: right;
`

export const FormWrapper = styled.div`
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Form = styled(BaseForm)`
  width: 50vw;
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 70vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100vw;
    height: 100%;
  }
`

export const LogoText = styled.div`
  font-size: 40px;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-bottom: 15px;
`
