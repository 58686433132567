import styled from 'styled-components'

export const Wrapper = styled.div<{ even?: boolean }>`
  display: flex;
  flex-direction: ${({ even }) => (even ? 'column-reverse' : 'column')};
  align-items: center;
  width: 14vw;
  padding-top: ${({ even }) => (even ? '150px' : '0px')};
`

export const Line = styled.img`
  height: 70px;
`

export const BigArrow = styled.div<{ src: string }>`
  margin-top: 200px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  width: 100%;
  /* height: 45vh; */
  display: flex;
  justify-content: center;
`

export const AreaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`

export const Point = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
`

export const PageWrapper = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 5vh;
  padding-top: 5vh;
`
