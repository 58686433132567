import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { getUUID } from '../../utility'
import DragItem from './DragItem/DragItem'
import { useEffect, useState } from 'react'

const DragNDropList = ({ items, renderItem, callback }) => {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }))
  const [activeId, setActiveId] = useState(null)

  useEffect(() => {
    const addSortingUUID = (items) => {
      return items.map((el) => ({ ...el, sortingUUID: el.sortingUUID || getUUID() }))
    }
    if (items?.some((el) => !el.sortingUUID)) callback(addSortingUUID(items))
  }, [items, callback])

  const handleDragStart = (event) => setActiveId(event.active.id)

  const handleDragEnd = (event) => {
    const { active, over } = event

    let newItems = [...items]

    if (active.id !== over.id) {
      const indexOfActive = newItems.findIndex((el) => el.sortingUUID === active.id)
      const indexOfOver = newItems.findIndex((el) => el.sortingUUID === over.id)

      newItems = arrayMove(newItems, indexOfActive, indexOfOver)

      // setitems(newItems)
    }

    setActiveId(null)
    callback(newItems)
  }

  if (!Array.isArray(items)) return <></>
  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart} collisionDetection={closestCenter}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <DragItem key={item.sortingUUID} item={item} renderItem={renderItem} id={item.sortingUUID} />
        ))}
        <DragOverlay>
          {activeId ? <DragItem item={items.find((el) => el.sortingUUID === activeId)} renderItem={renderItem} id={items.find((el) => el.sortingUUID === activeId).sortingUUID} /> : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  )
}

export default DragNDropList
