import styled from 'styled-components'
import PageLayout from '../PageLayout/PageLayout'
import endImg from '../../../../assets/image/workshop/end.png'
import { Link } from 'react-router-dom'

const Image = styled.div`
  background-image: url(${endImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: relative;
`

const CustomLink = styled(Link)`
  position: absolute;
  bottom: 5vh;
  right: 5vw;
`

const End = ({ workshop }) => {
  console.log(workshop)
  return (
    <PageLayout>
      <Image>
        <CustomLink to={`/dashboard/client?uuid=${workshop.clientUUID}`}>exit</CustomLink>
      </Image>
    </PageLayout>
  )
}

export default End
