import { useNavigate } from 'react-router-dom'
import { StyledItem, Name } from './Item.Styled'

const Item = ({ client }) => {
  const navigate = useNavigate()
  return (
    <StyledItem onClick={() => navigate(`/dashboard/client?uuid=${client.uuid}`)}>
      <Name>{client.name}</Name>
    </StyledItem>
  )
}

export default Item
