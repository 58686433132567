import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../../Layout/Layout'
import { Button, Container } from '@think-internet/ui-components'
import { Operations, crudInterface, useCrudReadOperation } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { Name } from './Client.Styled'
import { useSelector } from 'react-redux'

const Client = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('uuid')
  const client = useCrudReadOperation(props.CLIENT, Operations.GET, { uuid }, undefined, undefined, [uuid])
  const workshops = useCrudReadOperation(props.WORKSHOP, Operations.LIST, { clientUUID: uuid }, undefined, undefined, [uuid])
  const token = useSelector((s) => s[props.TOKEN])
  const navigate = useNavigate()

  const newWorkshop = async () => {
    const result: any = await crudInterface.create(token, props.WORKSHOP, { clientUUID: uuid })
    if (result) {
      navigate(`/dashboard/workshop?uuid=${result.uuid}`)
    }
  }

  return (
    <Layout>
      <Container>
        <Name>{client?.name}</Name>
        <Button text="Neuer Workshop" onClick={newWorkshop} />
        {workshops?.map((workshop) => (
          <div onClick={() => navigate(`/dashboard/workshop?uuid=${workshop.uuid}`)}>{workshop.createdOn}</div>
        ))}
      </Container>
    </Layout>
  )
}

export default Client
