import { StyledImageLayout, Image, Column } from './ImageLayout.styles'

const ImageLayout = ({ image, children }) => {
  return (
    <StyledImageLayout>
      <Image src={image} />
      <Column>{children}</Column>
    </StyledImageLayout>
  )
}

export default ImageLayout
