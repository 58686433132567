import styled from 'styled-components'

export const StyledImageLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex-direction: column;
  }
`
export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 50%;
  height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
    height: 50vh;
  }
`
export const Column = styled.div<{ even?: boolean; first: boolean; last: boolean }>`
  width: 50%;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 50px;
  padding-top: ${({ even }) => (even ? '150px' : '50px')};

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
  }
`
