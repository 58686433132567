import PageLayout from '../PageLayout/PageLayout'
import Textarea from '../Textarea/Textarea'
import { Headline, Seperator } from '../Wishes/Wishes.Styled'
import { Wrapper, Line, BigArrow, AreaWrapper, Point, PageWrapper } from './RoadMap.Styled'
import lineImg from '../../../../assets/image/workshop/line.png'
import bigArrowImg from '../../../../assets/image/workshop/bigArrow.png'
import _ from 'lodash'
import DragNDropList from '../../../DragNDropList/DragNDropList'
import { getUUID } from '../../../../utility'
import { useEffect, useState } from 'react'

const CustomTextarea = ({ value, onChange, even, DragableElement }) => {
  return (
    <Wrapper even={even}>
      <AreaWrapper>
        <DragableElement>
          <Point />
        </DragableElement>
        <Textarea value={value} onChange={onChange} />
      </AreaWrapper>
      <Line src={lineImg} />
    </Wrapper>
  )
}

type Item = {
  index: number
  value: string
  sortingUUID: string
}

const RoadMap = ({ workshop, setWorkshop }) => {
  const [items, setItems] = useState<Array<Item>>()

  useEffect(() => {
    if (!items) setItems(_.times(7).map((i) => ({ index: i, value: workshop.doings[i], sortingUUID: getUUID() })))
  }, [workshop, items])

  const update = (i, key) => (e) => {
    console.log(i, key)
    const values = [...workshop[key]]
    values[i] = e.target.value

    setWorkshop({ ...workshop, [key]: values })
    setItems(_.times(7).map((i) => ({ index: i, value: values[i], sortingUUID: items?.[i]?.sortingUUID || getUUID() })))
  }

  const callback = (newItems) => {
    setWorkshop({ ...workshop, doings: newItems.map((item) => item.value) })
    setItems(newItems.map((item, i) => ({ ...item, index: i })))
  }

  return (
    <PageLayout>
      <PageWrapper>
        <Headline>Road Map</Headline>
        <Seperator />

        <BigArrow src={bigArrowImg}>
          <DragNDropList
            items={items}
            renderItem={(item, DragableElement) => (
              <CustomTextarea key={item.index} DragableElement={DragableElement} even={item.index % 2 === 0} value={item.value || ''} onChange={update(item.index, 'doings')} />
            )}
            callback={callback}
          />
        </BigArrow>
      </PageWrapper>
    </PageLayout>
  )
}

export default RoadMap
