import ImageLayout from '../ImageLayout/ImageLayout'
import PageLayout from '../PageLayout/PageLayout'
import Textarea from '../Textarea/Textarea'
import { Headline, InputWrapper, Seperator } from '../Wishes/Wishes.Styled'
import potentials from '../../../../assets/image/workshop/potentials.png'
import _ from 'lodash'

const Potentials = ({ workshop, setWorkshop }) => {
  const update = (i) => (e) => {
    const potentials = [...workshop.potentials]
    potentials[i] = e.target.value
    setWorkshop({ ...workshop, potentials })
  }

  return (
    <PageLayout>
      <ImageLayout image={potentials}>
        <Headline>Marktchancen &</Headline>
        <Headline>Unternehmenspotentiale</Headline>
        <Seperator />

        <InputWrapper>
          {_.times(7).map((i) => (
            <Textarea key={i} value={workshop.potentials[i] || ''} onChange={update(i)} />
          ))}
        </InputWrapper>
      </ImageLayout>
    </PageLayout>
  )
}

export default Potentials
