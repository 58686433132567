import styled from 'styled-components'

export const Headline = styled.div<{ align?: string; color?: string }>`
  /* font-size: ${({ theme }) => theme.font.size.xlg}; */
  font-size: 2vh;
  color: ${({ theme, color }) => color || theme.color.font.thirdary};
  text-align: ${({ align }) => align || 'center'};
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 50px;
  }
`
export const Seperator = styled.div<{ align?: string }>`
  width: 10%;
  margin: 10px ${({ align }) => (align === 'right' ? '0' : 'auto')} 20px ${({ align }) => (align === 'left' ? '0' : 'auto')};
  height: 1px;
  background-color: ${({ theme }) => theme.color.font.thirdary};
`
export const Image = styled.img`
  max-width: 30%;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;
`
