import ImageLayout from '../ImageLayout/ImageLayout'
import PageLayout from '../PageLayout/PageLayout'
import Textarea from '../Textarea/Textarea'
import { Headline, InputWrapper, Seperator } from '../Wishes/Wishes.Styled'
import challenges from '../../../../assets/image/workshop/challenges.png'
import _ from 'lodash'

const Challanges = ({ workshop, setWorkshop }) => {
  const update = (i) => (e) => {
    const challenges = [...workshop.challenges]
    challenges[i] = e.target.value
    setWorkshop({ ...workshop, challenges })
  }

  return (
    <PageLayout>
      <ImageLayout image={challenges}>
        <Headline>Hürden &</Headline>
        <Headline>Aktuelle Herausforderungen</Headline>
        <Seperator />

        <InputWrapper>
          {_.times(7).map((i) => (
            <Textarea key={i} value={workshop.challenges[i] || ''} onChange={update(i)} />
          ))}
        </InputWrapper>
      </ImageLayout>
    </PageLayout>
  )
}

export default Challanges
