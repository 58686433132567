import { useSortable } from '@dnd-kit/sortable'
import { StyledDragItem } from './DragItem.Styled'
import { CSS } from '@dnd-kit/utilities'

const DragItem = ({ item, renderItem, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const DragableElement = ({ children }) => {
    return (
      <div {...attributes} {...listeners} id={id}>
        {children}
      </div>
    )
  }

  return (
    <StyledDragItem ref={setNodeRef} style={style}>
      {typeof renderItem === 'function' && renderItem(item, DragableElement)}
    </StyledDragItem>
  )
}

export default DragItem
