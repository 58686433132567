import { Navigate, Route, Routes } from 'react-router-dom'
import Clients from './Clients/Clients'
import Client from './Client/Client'
import Workshop from './Workshop/Workshop'

const Dashboard = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Clients />} />
      <Route path={'/client'} element={<Client />} />
      <Route path={'/workshop'} element={<Workshop />} />
      <Route path={'*'} element={<Navigate replace to="/dashboard" />} />
    </Routes>
  )
}

export default Dashboard
